import footer from '../assets/footer.svg'
import './Footer.scss'

function Footer() {
  return (
    <footer>
      <img src={footer} alt="flowers" />
    </footer>
  )
}

export default Footer
